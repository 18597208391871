<template>
  <div class="course_item_container">
    <div class="teacher">
      <div class="stars">
        推荐指数
        <img class="star" src="/static/img/mobile/star.png" v-for="key in 5" :key="key" />
      </div>
      <img class="badge" src="/static/img/mobile/teacher_now.png">
      <img class="cover" :src="item.pic">
      <div class="line">
        <span>系列精品课程</span>
      </div>
      <div class="button" @click="showFullScreenVideo(item.try_video)">
        <div class="icon"></div>
        免费试看
      </div>
    </div>
    <div class="courses">
      <div class="course" v-for="(course, key) in item.courses" :key="key">
        <img class="top-pic" :src="course.pic">
        <div class="title">{{course.name}}</div>
        <div class="info">
          <div class="text">
            价格：<span class="number">￥{{course.price}}</span>
          </div>
          <div class="action">
            <div class="button" @click="info(course.id)">立即购买</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  created() {},
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    info() {
      document.documentElement.scrollTo(
        0,
        document.documentElement.scrollHeight
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.course_item_container {
  height: 1148px;
  width: 990px;

  .teacher {
    height: 694px;
    position: relative;

    display: flex;
    flex-direction: column;

    .cover {
      width: 990px;
      margin: 0;
      padding: 0;
    }
    .line {
      margin-top: -2px;
      width: 988px;
      height: 54px;
      border-radius: 0px 0px 16px 16px;
      border: 1px solid #ff6f1b;
      border-top: none;
      text-align: center;

      span {
        font-size: 24px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ff6f1b;
        line-height: 33px;

        position: absolute;
        left: 397px;
        bottom: -8px;
        background-color: #fff;
        padding: 25px;
      }
    }

    .stars {
      position: absolute;
      top: 103px;
      right: 0;

      display: flex;
      align-items: center;

      font-size: 25px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 36px;

      .star {
        width: 27px;
        height: 31px;
        margin-left: 12px;
      }
    }
    .badge {
      position: absolute;
      width: 186px;
      height: 65px;
      right: 0;
      top: 175px;
    }
    .button {
      cursor: pointer;
      outline: none;

      display: flex;
      align-items: center;
      justify-content: center;

      position: absolute;
      bottom: 150px;
      right: 270px;

      width: 178px;
      height: 44px;
      background: #ffffff;
      border-radius: 22px;

      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ff4d00;
      line-height: 20px;

      .icon {
        width: 14px;
        height: 11px;
        background: url(/static/img/mobile/video_icon.png);
        background-size: 100%;
        margin-right: 19px;
      }

      transition: 0.5s;

      &:hover {
        transform: scale(1.1);
        background: $font-color-orange;
        border: 1px solid $border-color-orange;
        color: white;
        font-weight: bold;

        .icon {
          background: url(/static/img/mobile/video_icon_hover.png);
          background-size: 100%;
        }
      }
    }
  }

  .courses {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 69px;

    .course {
      position: relative;
      z-index: 99999;
      transition: 0.5s;
      &:hover {
        transform: scale(1.05);
      }
      box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.11);
      border-radius: 16px;
      margin-right: 30px;
      width: 305px;
      height: 388px;
      &:last-child {
        margin-right: 0;
      }

      .top-pic {
        width: 305px;
        height: 225px;
        border-radius: 16px 16px 0px 0px;
      }

      .title {
        font-size: 18px;

        line-height: 25px;
        font-family: PingFangSC-Medium, PingFang SC;
        text-shadow: 0px 2px 16px rgba(0, 0, 0, 0.11);
        font-weight: bold;
        color: #333333;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        margin-top: 20px;
        margin-left: 15px;
      }

      .info {
        display: flex;
        flex-direction: column;

        .text {
          margin-top: 8px;
          margin-left: 15px;

          height: 20px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #999999;
          line-height: 20px;
          text-shadow: 0px 2px 16px rgba(0, 0, 0, 0.11);

          .number {
            font-size: 17px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ff4d00;
            line-height: 20px;
          }
        }

        .action {
          .button {
            margin-left: auto;
            margin-right: 20px;
            margin-top: 35px;

            display: flex;
            align-items: center;
            justify-content: center;

            width: 100px;
            height: 30px;
            background: linear-gradient(180deg, #ffa83a 0%, #ff6f1b 100%);
            border-radius: 26px;

            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #ffffff;
            line-height: 22px;

            border: 1px solid #ffffff;
            cursor: pointer;
            transition: 1s;

            color: #ff6f1b;
            background: none;
            border: 1px solid #ff6f1b;
            &:hover {
              transform: scale(1.06);
            }
          }
        }
      }
    }
  }
}
</style>