<template>
  <div class="container">
    <!-- <div v-if="false"> -->
    <Carousel :args="args.slide" tip="加入" />
    <RecommendedCourses :args="args.teacher" />
    <AppQrcode :args="args.app" />
    <!-- </div> -->

  </div>
</template>

<script>
import Carousel from "@/components/common/carousel.vue";
import RecommendedCourses from "@/components/online_course/recommended_courses/index.vue";
import AppQrcode from "@/components/online_course/app_qrcode.vue";
export default {
  components: {
    Carousel,
    RecommendedCourses,
    AppQrcode,
  },
  created() {
    this.$api("/official/page/online_course").then(({ data }) => {
      this.args = data;
    });
  },
  data() {
    return {
      args: {},
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.container {
  width: 1440px;
}
</style>

