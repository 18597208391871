<template>
  <div class="app_qrcode_container">
    <div class="title">
      <div class="main">更多精彩课程 下载筒筒网课</div>
      <div class="sub">传播汉语 赋能未来</div>
    </div>

    <div class="body">
      <div class="qrcode-block">
        <div class="qrcode">
          <img :src="args.qrcode" />
        </div>
        <div class="badge">
          <img class="icon" src="/static/img/online_course/app_qrcode/icon_phone.png">
          <div class="label">扫码下载</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    args: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.app_qrcode_container {
  background-color: rgba($color: #ffffff, $alpha: 0.07);
  background: url("/static/img/online_course/app_qrcode/bg.png");
  background-size: 100%;
  background-repeat: no-repeat;
  height: 650px;

  .title {
    .main {
      padding-top: 50px;
      font-size: 36px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #ffffff;
      line-height: 50px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
    .sub {
      display: flex;
      align-items: center;
      justify-content: center;

      margin-top: 10px;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 22px;
    }
  }

  .body {
    padding-top: 187px;
    height: 332px;
    background: url("/static/img/online_course/app_qrcode/phone_border.png")
      no-repeat;
    background-size: 410px 463px;
    background-position: center top;
    background-position-y: 56px;

    overflow: hidden;

    display: flex;
    justify-content: center;

    .qrcode-block {
      position: relative;

      margin-top: -45px;

      width: 315px;
      height: 315px;
      background: rgba($color: #ff6f1b, $alpha: 0.49);
      border-radius: 25px;
      border: 1px solid rgba($color: #fff5ef, $alpha: 0.5);

      display: flex;
      align-items: center;
      justify-content: center;

      .qrcode {
        width: 230px;
        height: 230px;
        border-radius: 16px;
        background-color: #fff;

        display: flex;
        align-items: center;
        justify-content: center;

        img {
          padding: 35px;

          width: 170px;
          height: 170px;
        }
      }
      .badge {
        position: absolute;
        bottom: -21px;
        left: 0;
        right: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
          position: absolute;
          width: 50px;
          height: 50px;
          left: 70px;
          right: 0;
        }

        .label {
          width: 228px;
          height: 42px;
          background: #ff6f1b;
          border-radius: 29px;

          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 25px;

          display: flex;
          align-items: center;
          justify-content: center;

          text-indent: 40px;
        }
      }
    }
  }
}
</style>